import { SortingInfo } from "components/molecules/table/components/sorting-button/sorting-button.interface";
import {
    TableColumn,
    TableRow,
} from "components/molecules/table/table.interface";

export type UsersTableColumns = "avatar" | "userInfo" | "status" | "actions" | "username" | "role";

export type UsersProps = {
    usersList?: TableRow<UsersTableColumns>[];
    setSortBy: (data: SortingInfo) => void;
    sortBy: SortingInfo;
};

export const mobileColumns: TableColumn<UsersTableColumns>[] = [
    {
        name: "avatar",
        render: () => "",
    },
    {
        name: "userInfo",
        render: () => "",
    },
    {
        name: "status",
        render: () => "",
    },
    {
        name: "actions",
        render: () => "",
    },
];
