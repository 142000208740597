import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";

import { WarningModal } from "components/molecules/warning-modal/warning-modal.component";
import { BUTTONS_DIRECTION } from "components/molecules/warning-modal/warning-modal.constants";
import { useBlockUserMutation } from "lib/api/hooks/use-block-user-mutation.hook";
import { useGetUsersQueryKey } from "lib/api/hooks/use-get-users-query";
import { useUnblockUserMutation } from "lib/api/hooks/use-unblock-user-mutation.hook";
import {
    testIds,
    UserStatusWarningModalProps,
} from "./user-status-warning-modal.interface";
import { errorToast, successToast } from "lib/utils/toast";
import { useGetUserByIdQueryKey } from "lib/api/hooks/use-get-user-query.hook";

export const UserStatusWarningModal = ({
    isOpen,
    userData,
    close,
}: UserStatusWarningModalProps) => {
    const { t } = useTranslation("users");

    const queryClient = useQueryClient();

    const { mutate: unblockUser, isLoading: isBlocking } =
        useUnblockUserMutation({
            onSuccess: async () => {
                await queryClient.invalidateQueries([useGetUsersQueryKey]);
                await queryClient.invalidateQueries([
                    useGetUserByIdQueryKey,
                    userData.id,
                ]);
                close();
                successToast(
                    t("toast.successActivateUser", "User has been activated"),
                    { className: testIds.activateUserSuccessToast },
                );
            },
            onError: () => {
                close();
                errorToast(undefined, {
                    className: testIds.activateUserErrorToast,
                });
            },
        });
    const { mutate: blockUser, isLoading: isUnblocking } = useBlockUserMutation(
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries([useGetUsersQueryKey]);
                await queryClient.invalidateQueries([
                    useGetUserByIdQueryKey,
                    userData.id,
                ]);
                close();
                successToast(
                    t("toast.successDeactivateUser", "User was deactivated"),
                    { className: testIds.deactivateUserSuccessToast },
                );
            },
            onError: () => {
                close();
                errorToast(undefined, {
                    className: testIds.deactivateUserErrorToast,
                });
            },
        },
    );

    const handleChangeUserStatus = (isBanned: boolean, id: string) => {
        if (isBanned) {
            unblockUser(id);
        } else {
            blockUser(id);
        }
    };

    return (
        <WarningModal
            opened={isOpen}
            onClose={close}
            onCancel={close}
            onOk={() => handleChangeUserStatus(userData.isBanned, userData.id)}
            okButtonVariant="outlined"
            cancelButtonVariant="primary"
            okButtonIsLoading={isBlocking || isUnblocking}
            okButtonText={
                userData.isBanned
                    ? t("button.activate", "Activate")
                    : t("button.deactivate", "Deactivate")
            }
            okButtonTestId={
                userData.isBanned
                    ? testIds.activateUserButton
                    : testIds.deactivateUserButton
            }
            cancelButtonText={t("button.cancel", "Cancel")}
            cancelButtonTestId={testIds.changeUserStatusModalCancelButton}
            title={t("title.attention", "Attention")}
            message={t(
                "description.activateDeactivateQuestion",
                "Are you sure you want to {{action}} the user {{username}}?",
                {
                    action: userData.isBanned
                        ? t("label.activate", "activate")
                        : t("label.deactivate", "deactivate"),
                    username: userData.username,
                },
            )}
            buttonsDirection={BUTTONS_DIRECTION.rowReverse}
        />
    );
};
