import { AggregateService } from "lib/api/api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { UiBackendv1ListB2BCompaniesResponse } from "../common/data-contracts";
import { HttpErrorType } from "../types";

export const useGetCompaniesQueryKey = "get-companies";

type QueryParams = {
    with_sub_children?: boolean;
    b2b_company_type?: string;
    b2b_company_ids?: string[];
};

type Options = Omit<
    UseQueryOptions<
        UiBackendv1ListB2BCompaniesResponse,
        HttpErrorType,
        UiBackendv1ListB2BCompaniesResponse,
        [typeof useGetCompaniesQueryKey, QueryParams]
    >,
    "queryKey" | "queryFn"
>;

export function useGetCompaniesQuery(options?: Options, query?: QueryParams) {
    return useQuery(
        [
            useGetCompaniesQueryKey,
            { ...query, b2b_company_ids: (query && query.b2b_company_ids) ? [...query.b2b_company_ids].sort() : [] },
        ],
        async () => {
            return (
                await AggregateService.uiBackendServiceListB2BCompanies(query)
            )?.data;
        },
        options,
    );
}
