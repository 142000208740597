import { Authv1User, Userv1User } from "lib/api/common/data-contracts";

export type UserData = Authv1User & Userv1User;

export type DeleteUserInfoType = { id: string; username: string };

export const FALLBACK_ERROR_MESSAGE =
    "Something went wrong. We were unable to upload the data. Try again or contact your administrator.";

export const testIds = {
    userStatus: "user-status",
    userRole: "user-role",
};
